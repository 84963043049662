import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

// 중복 오류 방지
//const originalPush = VueRouter.prototype.push;
//VueRouter.prototype.push = function push(location) {
//    return originalPush.call(this, location).catch(err => err);
//}

const routes = [
    {
        path: '/',
        component: () => import('../components/news/NewsList.vue'),
        name: 'NewsList',
        meta: {
            isLayout: 'main'
        }
    },
    {
        path: '/newsdetail',
        component: () => import('../components/news/NewsDetail.vue'),
        name: 'NewsDetail',
        meta: {
            isLayout: 'main'
        }
    },
     {
        path: '/board',
        component: () => import('../components/news/NewsList.vue'),
        name: 'BoardList',
        meta: {
            isLayout: 'board',
        }
    },
    {
        path: '/boarddetail',
        component: () => import('../components/news/NewsDetail.vue'),
        name: 'BoardDetail',
        meta: {
            isLayout: 'board',
        }
    },
    {
        path: '/mailer/reject',
        component: () => import('../components/mail/MailRejectView.vue'),
        name: 'Mailreject',
        meta: {
            isLayout: 'mail'
        }
    },
    {
        path: "/:anything(.*)",
        name: 'ErrorPage',
        component: () => import('../components/404Page.vue'),
        meta: {
            isLayout: 'error',
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return {
            top: 0,
        };
    },
})

 //라우터 전 

//if (router.mode === 'history') {
//    router.history.getCurrentLocation = function () {
//        let path = window.location.pathname
//        let base = router.history.base

//        // Removes base from path (case-insensitive)
//        if (base && path.toLowerCase().indexOf(base.toLowerCase()) === 0) {
//            path = path.slice(base.length)
//        }

//        return (path || '/') + window.location.search.toLowerCase() + window.location.hash 
//    }
//}

router.beforeEach((to, from, next) => {
    let langCode = 'en'
    if (to.query.lang == null || to.query.lang == undefined) {
        var browserLangCode =
            navigator.languages
                ? navigator.languages[0] : (navigator.language || navigator.userLanguage);

        langCode = browserLangCode.substring(0, 2).toLowerCase();
    }
    else {
        langCode = to.query.lang;
    }

    switch (langCode) {
        case "de":
        case "es":
        case "fr":
        case "id":
        case "jp":
        case "pt":
        case "ru":
        case "th":
        case "vn":
        case "tw":
            break;
        case "ja":
            langCode = 'jp';
            break;
        case "vi":
            langCode = 'vn';
            break;
        case "zh":
            langCode = 'tw';
            break;
        default:
            langCode = 'en';
            break;
    }

    store.state.lang = langCode
    next()

    

});

export default router
