<template>
    <ul :class="[ $route.path == '/board' || $route.path == '/boarddetail' ? 'active' : '' ]">
        <li v-for="(cate,idx) in categoryList"
            :key="idx"
            :value="cate.boardType"
            @click="clickCategory(cate)"
            :class="[ cate.boardType == `${$route.query.category}` ? 'active' : '' ]"
            class="tabEls">
            <font-awesome-icon :value="cate.boardType"
                               @click="clickCategory(cate)" class="categoriIcon" :icon="cate.icon" />
            {{ cate.name }}
        </li>
    </ul>

</template>

<script>
    export default {
        data() {
            return {
                categoryList: [],
            }
        },
        created() {
            this.$apiUrl.get(`/category/gcm/${this.$store.state.lang}`)
                .then((res) => {
                    this.categoryList = res.data
                    this.$store.state.otherLang = res.data[0].language.toLowerCase()

                    // 첫번째 category 선택
                    this.$store.dispatch('fetchCategory', res.data[0].boardType)
                })
                .catch((err) => {
                    // todo 오류처리 필요
                    console.log(err)
                })

        },
        mounted() {
          
        },
        methods: {
            clickCategory(evt) {
                if (this.$store.state.categoryBoardType != evt.boardType) {
                    this.$store.state.categoryBoardType = evt.boardType

                    if (this.$route.path == '/board' || this.$route.path == '/boarddetail' ) {
                        this.$router.push({
                            name: 'BoardList',
                            query: {
                                category: evt.boardType,
                                page: 1,
                                lang: this.$store.state.lang,
                            }
                        })
                    } else {
                        this.$router.push({
                            name: 'NewsList',
                            query: {
                                category: evt.boardType,
                                page: 1,
                                lang: this.$store.state.lang,
                            }
                        })
                    }
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
    ul {
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 0px 20px 5px rgba(0,0,0,0.5);
        z-index: 1;
        width: 100%;
        margin: 0 0 10px;
        &.active {
            position: static;
            width: 100%;
        }
        li {
            background: #fff;
            padding: 15px 5px;
            border: solid 1px #000;
            box-sizing: border-box;
            cursor: pointer;
            width: 100%;
            font-size: 20px;
            font-weight: bold;
            opacity: 0.6;

            .categoriIcon {
                font-size: 16px;
                opacity: 0.6;
                margin-bottom: 2px;
                margin-right: 4px;
            }

            &:nth-child(n) {
                border-right: 0;
            }

            &:last-child {
                border-right: solid 1px #000;
            }

            &.active {
                background: #265a88;
                color: #fff;
                opacity: 1;

                .categoriIcon {
                    font-size: 20px;
                    opacity: 1;
                }
            }
        }
    }

    @media all and (max-width: 1200px) {
        ul {
            width: 100%;
            top: -70px;

            li {
                font-size: 14px;
                padding: 15px 0px 15px 5px;

                .categoriIcon {
                    font-size: 14px;
                    margin-bottom: 0;
                }

                &.active {
                    .categoriIcon {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    @media all and (max-width: 768px) {
        ul {
            li {
                padding: 5px 10px;
                text-align: center;

                .categoriIcon {
                    display: block;
                    text-align: center;
                    width: 100%;
                }
            }
        }
    }
    @media all and (max-width: 640px) {
        ul {
            overflow-x: auto;
            box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.5);
            margin-bottom: 0px;
            display: inline-block;
            width: 100%;
            white-space: nowrap;
            li {
                font-size: 10px;
                padding: 5px;
                display: inline-block;
                font-weight: 500;
                width: 30%;

                .categoriIcon {
                    font-size: 12px;
                    margin-bottom: 4px;
                }

                &.active {
                    .categoriIcon {
                        font-size: 12px;
                    }
                }
            }
        }

    }
</style>