<template>

    <div class="footer">

        <img :src="`${addImagePath}./images/${$store.state.lang}/${getIsResponse}/footer.jpg`" />

        <div class="infoWrap">
            <div class="footer_btn_wrap">
                <a href="#layer1" @click="showPrivacyPopup">
                    <img :src="`${addImagePath}./images/${$store.state.lang}/${getIsResponse}/btn_footer1.png`" />
                </a>
                <a href="#layer2" @click="showServicePopup">
                    <img :src="`${addImagePath}./images/${$store.state.lang}/${getIsResponse}/btn_footer2.png`" />
                </a>
            </div>

            <div class="btn_wrap">
                <a href="https://go.onelink.me/app/33198a2e" target="_blank">
                    <img :src="`${addImagePath}./images/${$store.state.lang}/${getIsResponse}/btn_google.png`" />
                </a>
                <a href="https://go.onelink.me/app/ea0bcca1" target="_blank">
                    <img :src="`${addImagePath}./images/${$store.state.lang}/${getIsResponse}/btn_apple.png`" />
                </a>
            </div>

        </div>

        <div class="layer_popup" v-if="isPrivacyPopup" @click="showPrivacyPopup">
            <div v-html="htmlPrivacy">
            </div>
        </div>
        <div class="layer_popup" v-if="isServicePopup" @click="showServicePopup">
            <div v-html="htmlTOS"></div>
        </div>
        <div>
        </div>

    </div>

</template>

<script>

    export default {

        data() {
            return {
                isPrivacyPopup: false,
                isServicePopup: false,
                htmlPrivacy: '',
                htmlTOS: '',
                addImagePath: '',
            }
        },
        created() { 
            if (this.$route.path.includes('reject') ){
                this.addImagePath = '.'
            }
        },
        computed: {
            getIsResponse() {
                return this.$store.state.isResponse
            },
        },
        methods: {
            showPrivacyPopup() {
                this.$axios.get(`${this.addImagePath}./privacy/privacy_policy_${this.$store.state.lang}.html`)
                    .then((response) => {
                        this.htmlPrivacy = response.data
                        this.isPrivacyPopup = !this.isPrivacyPopup
                })
            },
            showServicePopup() {
                this.$axios.get(`${this.addImagePath}./privacy/terms_of_service_${this.$store.state.lang}.html`)
                    .then((response) => {
                        this.htmlTOS = response.data
                        this.isServicePopup = !this.isServicePopup
                })
            }

        }

    }

</script>

<style lang="scss" >
    @import '../assets/scss/_variables.scss';

    .footer {
        position: relative;
        min-width: 1200px;
        text-align:center;
        background: #0c1c2b;
        height: 768px;
        >img {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
        }
        .infoWrap {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            text-align: center;
            width: 100%;

            .footer_btn_wrap {
                display: flex;
                justify-content: center;
                align-items: center;
                a {
                    display: block;
                    img {
                        width: 100%;
                    }
                }

            } 
            .btn_wrap {
                margin-top: 30px;
            }
        }

        .layer_popup {
            position: fixed;
            z-index: 100;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            background: rgba(0,0,0,0.5);
            text-align: left;
            .inner {
                position: absolute;
                z-index: 1;
                top: 50%;
                left: 50%;
                width: 580px;
                height: 680px;
                margin: -340px 0 0 -290px;
                padding: 0 15px;
                background-color: #fff;
                display: flex;
                flex-direction: column;
                border-radius: 10px;

                .head {
                    position: relative;
                    border-bottom: 1px solid #000;
                    padding-top: 15px;
                    padding-bottom: 10px;

                    h1 {
                        margin: 0;
                        font-size: 26px;
                    }

                    a {
                        position: absolute;
                        right: 0;
                        top: 14px;
                        display: block;
                    }
                }

                .body {
                    overflow-y: auto;
                    height: 615px;
                    padding-top: 12px;
                    padding-bottom: 15px;
                    margin-top: 15px;
                    margin-bottom: 15px;

                    h2 {
                        font-size: 14px;
                        font-weight: 500;
                        margin-bottom: 9px;
                        color: #333;
                    }

                    h3 {
                        font-size: 16px;
                    }

                    p {
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 18px;
                        color: #333;
                    }

                    > div {
                        margin-bottom: 20px;
                        font-size: 12px;
                    }
                }
            }
        }


        @include mobile {
            
            min-width: auto;
            height: auto;
            > img {
                width: 100%;
                position: static;
                transform: translate(0)
            }

            .infoWrap {
                position: static;
                transform: translate(0);
                background: #0f1923;

                .btn_wrap {
                    display: flex;

                    a {
                        img {
                            width: 100%;
                        }
                    }
                }
            }

            .layer_popup {
                .inner {
                    width: 300px;
                    height: 70%;
                    margin: 0;
                    transform: translate(-50%, -50%);

                    .head {
                        h1 {
                            margin: 0;
                            font-size: 16px;
                        }

                        a {
                            width: 20px;
                            height: 20px;

                            img {
                                width: 100%;
                            }
                        }
                    }

                    .body {
                        overflow-y: auto;
                        height: 615px;
                        padding-top: 5px;
                        padding-bottom: 5px;
                        margin-top: 5px;
                        margin-bottom: 5px;

                        h3 {
                            font-size: 14px;
                        }

                        div {
                            font-size: 11px;
                        }

                        p {
                            font-size: 11px;
                        }
                    }
                }
            }
        }
    }

</style>