<template>
    <div class="promotionVideo ">

        <div class="section2">
            <div class="playVideo">
                <img :src="`./images/${$store.state.lang}/${getIsResponse}/title_mov.png`" />

                <div class="iframeCover">
                    <iframe :src="transYoutubeLink"
                            width="640" height="360" frameborder="0" allow="autoplay; encrypted-media"
                            style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; "
                            allowfullscreen="">
                    </iframe>
                </div>
            </div>

            <!-- 캐릭터 슬라이드 -->
            <Characters></Characters>

        </div>

    </div>
</template>

<script>
    import Characters from '@/components/Characters.vue'

    export default {
        components: {
            Characters
        },
        data() {
            return {
                transYoutubeLink: '',
                youtubeId: [
                    {
                        "en": "lVcHaBpq6Ec",
                        "de": "1rN04rE4VpY",
                        "es": "rOXXSqNGc-g",
                        "fr": "A39q_cLqRtw",
                        "id": "uZspFGKvDSI",
                        "pt": "FOZDTcYs2ug",
                        "jp": "TfzPYkXfXaU",
                        "ru": "lVcHaBpq6Ec",
                        "th": "9qomoPlH7zw",
                        "vn": "8RYuBdsqxGM"
                    },
                ]
            }
        },
        created() {
            const _self = this
            this.youtubeId.forEach((el) => {
                const idLink = el[this.$store.state.lang]

                _self.transYoutubeLink = 'https://www.youtube.com/embed/' + idLink + '?autoplay=0&showinfo=0&amp;wmode=transparent&amp;rel=0&amp;autohide=1'

            })

        },
        computed: {
            getIsResponse() {
                return this.$store.state.isResponse
            },
            rootPath() {
                return this.$store.state.rootPath
            }
        },
        mounted() {
        },
        methods: {
        
        }
    }

</script>

<style lang="scss" scoped>
    @import '../assets/scss/_variables.scss';

    .promotionVideo {
        position: relative;
        text-align: center;
        background: url('../../public/images/bg_char.jpg')  center/cover;
        background-position: 50% bottom;
        width: 100%;
        height: auto;
        .section2 {
            width: 1200px;
            margin: 0 auto;
 
        }

        .iframeCover {
            position: relative;
            width: 100%;
            height: 0;
            padding-bottom: 56.25%;
        }

        @include laptop {
            .section2 {
                width: 100%;
                padding: 0 10px;

                img {
                    position: relative;
                    left: 50%;
                    transform: translateX(-50%);
                    width: auto;
                }
            }
        }

        @include mobile {
            > img {
                width: 100%;
            }

            .section2 {
                img {
                    width: 100%;
                }
            }
        }
    }   
    
</style>