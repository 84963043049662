<template>

    <div class="eventMain">

        <!--<video playsinline="playsinline" id="eventVideo" autoplay="autoplay" muted="muted" loop="loop">
        <source src="video/thumb.mp4" type="audio/mp4">
    </video>-->

        <div class="mainImage">
            <img src="images/event/1P_main.png" />
        </div>

        <div class="leftGradient"></div>

        <div class="logo">
            <img :src="`images/${$store.state.lang}/pc/gc_logo.png`" />
        </div>

        <div class="inner">

            <div v-if="$store.state.lang == 'vn'"
                 class="eventLogo">
                <img v-if="!$store.state.isRegEndTime"
                     :src="`images/event/en_logo.png`" />
                <img v-else
                     :src="`images/event/en_logo_update.png`" />
            </div>

            <div v-else
                 class="eventLogo">
                <img v-if="!$store.state.isRegEndTime"
                     :src="`images/event/${$store.state.lang}_logo.png`" />
                <img v-else
                     :src="`images/event/${$store.state.lang}_logo_update.png`" />
            </div>

            <!--<div v-if="!$store.state.isRegEndTime"
             class="txtBox">
            <p class="tt1">
                {{ transEventTxt.p1_title_01[$store.state.lang] }}
            </p>
            <p class="tt2">
                {{ transEventTxt.p1_title_02[$store.state.lang] }}
            </p>
        </div>-->
            <div class="goEventSite">

                <a @click="goEventSite">
                    <span v-if="$store.state.lang == 'vn'">
                        GO TO PRE-REGISTRATION
                    </span>
                    <span v-else>
                        {{ transEventTxt.p1_title_03[$store.state.lang] }}
                    </span>
                </a>
            </div>

            <div v-if="$store.state.lang =='tw'"
                 class="playBtnBox">
                <a v-for="btn in twPlayBtn"
                   :key="btn.name"
                   :href="btn.href" target="_blank">
                    <img :src="`./images/${$store.state.lang}/${getIsResponse}/${btn.src}`" />
                </a>
            </div>

            <div v-else
                 class="playBtnBox">
                <a v-for="btn in playBtn"
                   :key="btn.name"
                   :href="btn.href" target="_blank">
                    <img :src="`./images/${$store.state.lang}/${getIsResponse}/${btn.src}`" />
                </a>
            </div>

        </div>



    </div>

</template>

<script>

    export default {
        props: {
            isEventTime: {
                type: Boolean,
                dafault: false
            }
        },
        data() {
            return {
                eventSiteUrl: process.env.VUE_APP_EVENT_SITE,
                transEventTxt: {
                    "p1_title_01": { "kr": "사전예약 개최 중!", "en": "Pre-Registration in Progress!", "de": "Vorregistrierung im Gange!", "fr": "Pré-enregistrement en cours !", "es": "¡Preinscripción en Andamiento!", "pt": "Pré-Registro em Andamento!", "ru": "Пре-регистрация уже идёт!", "th": "กิจกรรมลงทะเบียนล่วงหน้าเริ่มขึ้นแล้ว!", "jp": "事前登録開催中！", "tw": "事前預約進行中！", "id": "Pra-Registrasi sedang berlangsung!" },
                    "p1_title_02": { "kr": "사전예약하고 특별한 보상을 받아가세요!", "en": "Pre-Register and Receive a Special Reward!", "de": "Vorregistrieren und eine besondere Belohnung erhalten!", "fr": "Pré-enregistrez-vous et recevez une récompense spéciale !", "es": "¡Preinscríbete y Recibe una Recompensa Especial!", "pt": "Faça o Pré-Registro e Receba uma Recompensa Especial!", "ru": "Пройдите пре-регистрацию, и получите особую награду!", "th": "ลงทะเบียนล่วงหน้า รับรางวัลสุดพิเศษ!", "jp": "事前登録して特別な報酬を手に入れましょう！", "tw": "參與事前預約，獲得特別獎勵吧！", "id": "Daftar Pra-Registrasi dan dapatkan Hadiah Spesial!" },
                    "p1_title_03": { "kr": "사전예약 페이지로 이동", "en": "GO TO PRE-REGISTRATION", "de": "GEHE ZUR VORREGISTRIERUNG", "fr": "SE PRÉ-ENREGISTRER", "es": "IR A LA PREINSCRIPCIÓN", "pt": "IR PARA O PRÉ-REGISTRO", "ru": "К ПРЕ-РЕГИСТРАЦИИ", "th": "ไปที่หน้าลงทะเบียน", "jp": "事前登録ページヘ", "tw": "移動至事前預約界面", "id": "DAFTAR PRA-REGISTRASI" },

                },
                playBtn: [
                    {
                        name: 'androiod',
                        src: 'btn_google.png',
                        href: 'https://grandchaseglobal.onelink.me/htmq/tox3mhp7'
                    },
                    {
                        name: 'ios',
                        src: 'btn_apple.png',
                        href: 'https://grandchaseglobal.onelink.me/htmq/tox3mhp7'
                    }
                ],
                twPlayBtn: [
                    {
                        name: 'androiod',
                        src: 'btn_google.png',
                        href: 'https://grandchasetw.onelink.me/L6pT/hzvms7pb'
                    },
                    {
                        name: 'ios',
                        src: 'btn_apple.png',
                        href: 'https://grandchasetw.onelink.me/L6pT/hzvms7pb'
                    }
                ]
            }
        },
        computed: {
            getIsResponse() {
                return this.$store.state.isResponse
            },
        },
        methods: {
            goEventSite() {
                window.open(this.eventSiteUrl + '?lang=' + this.$store.state.lang, '_blank')
            }
        }
    }

</script>

<style lang="scss" scoped>
    @import '../assets/scss/_variables.scss';

    .eventMain {
        position: relative;
        height: 100%;
        min-height: 1080px;
        width: 100%;
        overflow: hidden;

        .mainImage {
            img {
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                min-width: 100%;
                min-height: 100%;
                width: auto;
                height: auto;
                z-index: 0;
                transform: translate(-50%, -50%);
            }
        }

        video {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            min-width: 100%;
            min-height: 100%;
            width: auto;
            height: auto;
            z-index: 0;
            transform: translate(-50%, -50%);
        }

        .leftGradient {
            position: absolute;
            width: 100%;
            left: 0%;
            bottom: 0;
            height: 45%;
            background: radial-gradient(farthest-side at 50% 100%, #000, #000, transparent);
            opacity: 0.7;
        }

        .logo {
            position: absolute;
            top: 70px;
            right: 20px;

            img {
                width: 240px;
            }
        }

        .inner {
            position: absolute;
            z-index: 2;
            width: 55%;
            margin: 0 auto;
            left: 50%;
            bottom: 50px;
            transform: translateX(-50%);
            text-align: center;

            .logo {
                img {
                    width: 240px;
                }
            }

            .eventLogo {
            }

            .txtBox {
                margin-bottom: 30px;

                .tt1 {
                    font-size: 50px;
                    color: #ff5d68;
                    font-weight: 900;
                    font-family: 'Pretendard';
                    margin-bottom: 0 !important;
                    text-transform: uppercase;
                }

                .tt2 {
                    font-size: 30px;
                    font-weight: 500;
                    font-family: 'Pretendard';
                    color: #f6f6f7;


                    span {
                        &:first-child {
                        }

                        &:nth-child(2) {
                            margin-left: 10px;
                        }
                    }
                }
            }

            .goEventSite {

                a {
                    display: inline-block;
                    padding: 15px 110px;
                    font-size: 32px;
                    font-weight: 900;
                    color: #ffffff;
                    background: #eb816c;
                    text-decoration: none;
                    font-family: 'Pretendard';
                    transition: all 0.4s;
                    cursor: pointer;
                    border: solid 1px #ff9393;

                    &:hover {
                        background: #fff;
                        color: #eb816c
                    }
                }
            }
        }

        .playBtnBox {
            margin-top: 20px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        @include laptop {
            .leftGradient {
                background: linear-gradient(to top, #000, transparent);
                height: 70%;
                bottom: 0;
                top: auto;
            }

            .logo {
                margin-bottom: 10px;

                img {
                    width: 160px;
                }
            }

            .inner {
                position: absolute;
                z-index: 2;
                width: 100%;
                margin: 0 auto;
                left: 50%;
                bottom: 40px;
                transform: translateX(-50%);
                text-align: center;

                .logo {
                    margin-bottom: 10px;

                    img {
                        width: 160px;
                    }
                }

                .eventLogo {
                    margin-bottom: 10px;

                    img {
                    }
                }

                .txtBox {
                    margin-bottom: 10px;

                    .tt1 {
                        font-size: 36px;
                    }

                    .tt2 {
                        font-size: 26px;
                    }
                }

                .goEventSite {
                    a {
                        font-size: 26px;
                    }
                }
            }

            .playBtnBox {
                bottom: 10px;

                a {
                    img {
                    }
                }
            }
        }

        @include tablet {
            min-height: auto;
            background: url('../../public/images/event/1P_thumb_mobile.png') no-repeat center 0/cover;
            padding: 500px 0 20px;

            .mainImage {
                display: none;

            } video {
                display: none;
            }

            .leftGradient {
                background: linear-gradient(to top, #000, transparent);
                height: 70%;
                bottom: 0;
                top: auto;
            }

            .logo {
                top: 50px;
                right: 10px;

                img {
                    width: 100px;
                }
            }

            .inner {
                position: static;
                transform: translateX(0);


                .logo {
                    margin-bottom: 10px;

                    img {
                        width: 100px;
                    }
                }

                .eventLogo {
                    margin-bottom: 10px;

                    img {
                        width: 300px;
                    }
                }

                .txtBox {
                    margin-bottom: 10px;

                    .tt1 {
                        margin-bottom: 0px;
                        font-size: 18px;
                    }

                    .tt2 {
                        font-size: 12px;
                    }
                }

                .goEventSite {
                    a {
                        font-size: 16px;
                        padding: 10px 40px;
                    }
                }
            }

            .playBtnBox {
                bottom: 10px;

                a {
                    img {
                        width: 160px;
                    }
                }
            }
        }

        @include mobile {
            padding: 350px 0 20px;
        }
    }
    
</style>