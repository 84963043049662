<template>
    <div >

        <div class="dropdown">
            <select v-model="seletedLang" id="selectbox" name="lang" @change="changeLang()">
                <option v-for="lang in language"
                        :key="lang.id"
                        :value="lang.code">
                     {{ lang.name }}
                </option>
            </select>
        </div>

        <MenuView></MenuView>

    </div>

</template>

<script>
    import MenuView from '@/components/Menu.vue'

    export default {
        components: {
            MenuView
        },
        data() {
            return {
                firstLang: '',
                seletedLang: this.$store.state.lang,
                language: [
                    {
                        'id': 0,
                        'name': 'English',
                        'code': 'en',
                    },
                    {
                        'id': 1,
                        'name': 'Bahasa Indonesia',
                        'code': 'id',
                    },
                    {
                        'id': 2,
                        'name': 'Deutsch',
                        'code': 'de',
                    },
                    {
                        'id': 3,
                        'name': 'Español',
                        'code': 'es',
                    },
                    {
                        'id': 4,
                        'name': 'Francais',
                        'code': 'fr',
                    },
                    {
                        'id': 5,
                        'name': 'Português',
                        'code': 'pt',
                    },
                    {
                        'id': 6,
                        'name': 'Русский',
                        'code': 'ru',
                    },
                    {
                        'id': 7,
                        'name': '日本語',
                        'code': 'jp',
                    },
                    {
                        'id': 8,
                        'name': 'Tiếng Việt',
                        'code': 'vn',
                    },
                    {
                        'id': 9,
                        'name': 'ไทย',
                        'code': 'th',
                    },
                
                ]
            }
        },
        mounted() {
            if (this.$route.query.r) {
                this.language.push({
                    'id': 10,
                    'name': '臺灣',
                    'code': 'tw',
                })
            }
            
        },
        methods: {
            changeLang() {
                if (this.$route.path == '/newsdetail') {
                    this.$router.push({
                        name: 'NewsList',
                    })
                }
                if (this.$route.query.r) {
                    window.location.href = 'mailer/reject?lang=' + this.seletedLang + '&r=' + this.$route.query.r
                }
                window.location.href = '?lang=' + this.seletedLang

            },
        },
    }

</script>

<style lang="scss"  >
    .nav {
        position: relative;
    }
    select {
        -webkit-appearance: auto !important;
        -moz-appearance: button-arrow-down !important;
    }
    .dropdown {
        position: absolute !important;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
    }
    #selectbox {
        padding: 5px 0px;
        background: #265a88;
        color: #fff;
        border: solid 1px #265a88;
        font-weight: bold;
        border-radius: 4px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
    }
    #selectbox:focus {
        border-color: #aaa;
        box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
        box-shadow: 0 0 0 3px -moz-mac-focusring;
        outline: none;
    }
    select:disabled {
        opacity: 0.5;
    }
    option {
        padding: 14px 0;
    }

    @media all and (max-width: 640px) {
        #selectbox {
            padding: 8px 0px;
            font-weight: 400;
            border-radius: 4px;
            font-size: 12px;
        }
    }
</style>