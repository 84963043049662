<template>

    <div>
        <ul class="menu"
            :class="{active: isMenu}">
            <li v-for="menuText in langMenu"
                :key="menuText.anchor"
                class="custom_nav_li"
                @click="moveScroll(menuText.anchor)">
                <a :href="menuText.anchor" class="custom_nav_a" v-smooth-scroll>
                    <span class="custom_nav_style">
                        {{ menuText[$store.state.lang]}}
                    </span>
                </a>
            </li>
        </ul>

        <div class="mobileTap"
             @click="menuShow"
            :class="{active: isMenu}">
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div>

</template>

<script>

    export default {
        data() {
            return {
                isMenu: false,
                langMenu: [
                    {
                        'anchor': '#top',
                        'en': "TOP",
                        "de": "NACH OBEN",
                        "es": "CIMA",
                        "fr": "SOMMET",
                        "id": "TOP",
                        "pt": "TOPO",
                        "jp": "トップ",
                        "ru": "ТОП",
                        "th": "ด้านบน",
                        "vn": "TOP",
                        "tw": ""
                    },
                    {
                        'anchor': '#news',
                        'en': 'NEWS',
                        'de': 'NEUIGKEITEN',
                        'es': 'NOTICIAS',
                        'fr': 'ACTUALITÉS',
                        "id": "NEWS",
                        'pt': 'NOTÍCIAS',
                        'jp': 'ニュース',
                        'ru': 'НОВОСТИ',
                        'th': 'ข่าวสาร',
                        "vn": "NEWS",
                        "tw": ""
                    },
                    {
                        'anchor': '#mov',
                        "en": "PROMOTION VIDEO",
                        "de": "WERBEVIDEO",
                        "es": "VIDEO PROMOCIONAL",
                        "fr": "VIDÉO PROMOTIONNELLE",
                        "id": "VIDEO PROMOSI",
                        "pt": "VIDEO PROMOCIONAL",
                        "jp": "公式PV",
                        "ru": "РЕКЛАМНЫЙ РОЛИК",
                        "th": "วิดีโอโปรโมท",
                        "vn": "VIDEO QUẢNG CÁO",
                        "tw": ""
                    },
                    {
                        'anchor': '#char',
                        "en": "CHARACTERS",
                        "de": "ZEICHEN",
                        "es": "PERSONAJES",
                        "fr": "HISTOIRE",
                        "id": "KARAKTER",
                        "jp": "キャラクター",
                        "pt": "PERSONAGENS",
                        "ru": "ПЕРСОНАЖИ",
                        "th": "ตัวละคร",
                        "vn": "NHÂN VẬT",
                        "tw": ""
                    },
                    {
                        'anchor': '#story',
                        "en": "STORY",
                        "de": "GESCHICHTE",
                        "es": "HISTORIA",
                        "fr": "HISTOIRE",
                        "id": "STORY",
                        "ru": "СЮЖЕТ",
                        "jp": "ストーリー",
                        "pt": "HISTÓRIA",
                        "th": "เนื้อเรื่อง",
                        "vn": "TRUYỆN",
                        "tw": ""
                    },
                    {
                        'anchor': '#line',
                        "en": "COMMUNITY",
                        "de": "COMMUNITY",
                        "es": "COMUNIDAD",
                        "fr": "COMMUNAUTÉ",
                        "id": "KOMUNITAS",
                        "jp": "お知らせ",
                        "pt": "COMUNIDADE",
                        "ru": "СООБЩЕСТВО",
                        "th": "กลุ่มคอมมูนิตี้",
                        "vn": "CỘNG ĐỒNG",
                        "tw": ""
                    },
                    {
                        'anchor': '#system',
                        "en": "SYSTEM",
                        "de": "SYSTEM",
                        "es": "SISTEMA",
                        "fr": "SYSTÈME",
                        "id": "SYSTEM",
                        "jp": "システム",
                        "pt": "SISTEMA",
                        "ru": "СИСТЕМА",
                        "th": "ระบบ",
                        "vn": "HỆ THỐNG",
                        "tw": ""
                    }

                ]
            }
        },
        mounted() {
            /* this.mailerRejectMenuMove()*/

            console.log()
        },
        methods: {
            moveScroll(anchor) {
                this.isMenu = false
                //fnMove(target) {
                //    var offset = $("#" + target).offset();
                //    $('html, body').animate({
                //        scrollTop: offset.top
                //    }, 500, "swing");
                //    return false
                //};

                if (this.$route.query.r) {
                    this.$router.push('/?lang=' + this.$store.state.lang)
                }

            },
            menuShow() {
                this.isMenu = !this.isMenu
            },
            hideMenu() {
                this.isMenu = false
            },
            mailerRejectMenuMove() {
                if (this.$route.query.r) {
                    /*this.$router.push('/')*/
                }
            }
        }
    }

</script>

<style lang="scss" scoped>
    @import '../assets/scss/_variables.scss';

    .menu {
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        li {
            margin: 0 10px;
            a {
                color: #fff;
                text-decoration: none;
            }
        }

        @include laptop {
            display: none;
            width: 100%;
            height: 100vh;
            position: fixed;
            flex-direction: column;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: rgba(0,0,0,0.9);

            li {
                margin-bottom: 40px;
                font-size: 20px;
            }

            &.active {
                display: flex;
            }
        }

        @include mobile {
            li {
                margin-bottom: 20px;
                font-size: 16px;
            }
        }
    }
    .mobileTap {
        z-index: 1;
        position: absolute;
        top: 50%;
        padding: 10px;
        transform: translateY(-50%);
        right: 10px;
        cursor: pointer;

        @include laptop {
            display: block;

            &.active {
                span {
                    &:nth-child(1) {
                        transform: rotate(45deg) translate(5px, 3px);
                    }

                    &:nth-child(2) {
                        display: none;
                    }

                    &:nth-child(3) {
                        transform: rotate(-45deg) translate(2px, -1px);
                    }
                }
            }

            span {
                display: block;
                height: 3px;
                width: 36px;
                margin-bottom: 5px;
                background: #fff;
                transition: all 0.4s;
            }
        }

        @include mobile {

            &.active {
                span {
                    &:nth-child(1) {
                        transform: rotate(45deg) translate(5px, 2px);
                    }

                    &:nth-child(3) {
                        transform: rotate(-45deg) translate(2px, 0px);
                    }
                }
            }

            span {
                display: block;
                height: 2px;
                width: 27px;
                margin-bottom: 5px;
                background: #fff;
                transition: all 0.4s;
            }
        }
    }
    

   

</style>
