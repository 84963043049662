<template>
    <div class="community">
        <div class="communityBox">

            <img :src="`./images/${$store.state.lang}/${getIsResponse}/titie_line.png`" />

            <div class="line_box" v-if="$store.state.lang != 'jp'">
                <iframe v-if="getIsResponse == 'pc'"
                        src="https://www.facebook.com/plugins/page.php?href=https://www.facebook.com/GrandChaseGlobal&tabs=timeline&width=500&height=500&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=2173344942897617"
                        scrolling="no" frameborder="0" allowTransparency="true"
                        allow="encrypted-media"></iframe>

                <iframe v-else
                        src="https://www.facebook.com/plugins/page.php?href=https://www.facebook.com/GrandChaseGlobal&tabs=timeline&width=300&height=340&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=2173344942897617"
                        scrolling="no" frameborder="0" allowTransparency="true"
                        allow="encrypted-media"></iframe>
            </div>

            <div class="jpBox" v-if="$store.state.lang == 'jp'">
                <div class="line_box" >
                    <a class="twitter-timeline" href="https://twitter.com/GrandChase_JP?ref_src=twsrc%5Etfw">Tweets by GrandChase_JP</a>
                </div>

                <!--<div class="banner">
                    <a href="https://moot.us/lounges/403/">
                        <img :src="`./images/${$store.state.lang}/${getIsResponse}/banner.png`" />
                    </a>
                </div>-->

                <div class="line_wrap">
                    <a href="https://altema.jp/grandchase/" target="_blank">
                        <img :src="`./images/${$store.state.lang}/${getIsResponse}/btn_tw01.png`" />
                    </a>
                    <a href="line://ti/p/@ewa3524l" target="_blank">
                        <img :src="`./images/${$store.state.lang}/${getIsResponse}/btn_tw02.png`" />
                    </a>
                </div>
            </div>

            <div class="youtubeBox" style="padding-top:20px; text-align:center;" v-if="$store.state.lang != 'jp'">
                <a href="https://www.youtube.com/channel/UCsE3Fx6ALgJbu27-Lp8HlVA/" target="_blank">
                    <img :src="`./images/${$store.state.lang}/${getIsResponse}/Youtube_sub_1.8.png`" />
                </a>
            </div>
        </div>
    </div>

</template>

<script>
                    

    export default {

        computed: {
            getIsResponse() {
                return this.$store.state.isResponse
            },
            rootPath() {
                return this.$store.state.rootPath
            }
        },

    }

</script>

<style lang="scss" scoped>
    @import '../assets/scss/_variables.scss';

    .community {
        position: relative;
        background: url('../../public/images/bg_line.jpg') no-repeat center/cover;
        padding-bottom: 30px;

        .communityBox {
            width: 1200px;
            text-align: center;
            margin: 0 auto;

            .line_box {
                border: 3px solid #91bfe5;
                border-radius: 0px;
                background-color: #fff;
                position: relative;
                height: 0;
                width: 510px;
                height: 510px;
                margin: 0 auto;
                box-sizing: border-box;

                iframe {
                    border: 3px solid #91bfe5;
                    border-radius: 0px;
                    background-color: #fff;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }

            .youtubeBox {
                width: 510px;
                margin: 0 auto;

                img {
                    width: 100%;
                }
            }
        }

        .jpBox {
            .line_box {
                overflow-y: scroll;
            }

            .line_wrap {
                margin-top: 30px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                a {
                    margin: 10px 0;
                    display: inline-block;
                }
            }
        }

        @include laptop {
            .communityBox {
                > img {
                    width: 100%;
                }

                width: 100%;
            }
        }

        @include mobile {
            .communityBox {
                .line_box {
                    margin: 0 auto;
                    width: 304px;
                    height: 350px;
                    border: 2px solid #91bfe5;
                    border-radius: 10px;
                    background-color: #fff;
                    iframe {
                        border: none;
                        overflow: hidden;
                        width: 300px;
                        height: 340px;
                    }
                }
                .youtubeBox {
                    width: 304px;
                    margin: 0 auto;
                }
            }

            .jpBox {
                padding: 0 40px;
                .line_box {
                    width: 100%;
                    height: 300px;
                }

                .line_wrap {
                  

                    a {
                
                        img {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

</style>