<template>
    <div class="characters" id="char">
        <img :src="`./images/${$store.state.lang}/${getIsResponse}/titie_char.png`" />

        <Swiper class="swiperWrap" :options="swiperOption"
                :scrollbar="{ draggable: true }"
                mousewheel
                freeMode
                navigation>
            <SwiperSlide v-for="data in charactersData"
                         :key="data.id">
                <img class="characterImg" :src="`./images/${$store.state.lang}/${getIsResponse}/${data.illust}`" />
                <div class="txt">
                    <img :src="`./images/${$store.state.lang}/${getIsResponse}/${data.script}`" />
                    <img :src="`./images/${$store.state.lang}/${getIsResponse}/${data.name}`" />
                </div>
            </SwiperSlide>

            <div class="swiper-pagination"
                 slot="pagination">
            </div>

            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>

        </Swiper>
    </div>

</template>

<script>
    import 'swiper/css/swiper.css';
    import { Swiper, SwiperSlide } from 'vue-awesome-swiper';

    export default {
        components: {
            Swiper,
            SwiperSlide
        },
        data() {
            return {
                charactersData: [
                    {
                        id: 0,
                        illust: 'cha_illlust_01.png',
                        script: 'cha_script_011.png',
                        name: 'cha_name_01.png'
                    },
                    {
                        id: 1,
                        illust: 'cha_illlust_02.png',
                        script: 'cha_script_021.png',
                        name: 'cha_name_02.png'
                    },
                    {
                        id: 2,
                        illust: 'cha_illlust_03.png',
                        script: 'cha_script_031.png',
                        name: 'cha_name_03.png'
                    },
                    {
                        id: 3,
                        illust: 'cha_illlust_04.png',
                        script: 'cha_script_041.png',
                        name: 'cha_name_04.png'
                    },
                    {
                        id: 4,
                        illust: 'cha_illlust_05.png',
                        script: 'cha_script_051.png',
                        name: 'cha_name_05.png'
                    },
                    {
                        id: 5,
                        illust: 'cha_illlust_06.png',
                        script: 'cha_script_061.png',
                        name: 'cha_name_06.png'
                    },
                    {
                        id: 6,
                        illust: 'cha_illlust_07.png',
                        script: 'cha_script_071.png',
                        name: 'cha_name_07.png'
                    },
                    {
                        id: 7,
                        illust: 'cha_illlust_08.png',
                        script: 'cha_script_081.png',
                        name: 'cha_name_08.png'
                    },
                    {
                        id: 8,
                        illust: 'cha_illlust_09.png',
                        script: 'cha_script_091.png',
                        name: 'cha_name_09.png'
                    }

                ],
                swiperOption: {
                    slidesPerView: 1,
                    spaceBetween: 60,
                    effect:'fade', 
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true
                    },
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    },
                    freemode: true
                },
            }
        },
        computed: {
            getIsResponse() {
                return this.$store.state.isResponse
            },
        },

    }

</script>

<style lang="scss">
    @import '../assets/scss/_variables.scss';

    .characters {
        text-align: left;

        .swiperWrap {
            position: relative;
            padding-bottom: 300px;
            overflow: visible;

            .characterImg {
                position: absolute;
                top: 0;
                left: 0;
            }

            .swiper-pagination {
                margin-bottom: 70px;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                .swiper-pagination-bullet {
                    display: block;
                    width: 100px;
                    height: 135px;
                    background: url('../../public/images/thumb_char.jpg') 0 0 no-repeat;
                    border-radius: 0;
                    opacity: 1;

                    &.swiper-pagination-bullet-active {
                        opacity: 1;
                    }

                    &:nth-child(1) {
                        &.swiper-pagination-bullet-active {
                            background-position: 0 -150px
                        }
                    }

                    &:nth-child(2) {
                        background-position: -100px 0;

                        &.swiper-pagination-bullet-active {
                            background-position: -100px -150px
                        }
                    }

                    &:nth-child(3) {
                        background-position: -200px 0;

                        &.swiper-pagination-bullet-active {
                            background-position: -200px -150px
                        }
                    }

                    &:nth-child(4) {
                        background-position: -300px 0;

                        &.swiper-pagination-bullet-active {
                            background-position: -300px -150px
                        }
                    }

                    &:nth-child(5) {
                        background-position: -400px 0;

                        &.swiper-pagination-bullet-active {
                            background-position: -400px -150px
                        }
                    }

                    &:nth-child(6) {
                        background-position: -500px 0;

                        &.swiper-pagination-bullet-active {
                            background-position: -500px -150px
                        }
                    }

                    &:nth-child(7) {
                        background-position: -600px 0;

                        &.swiper-pagination-bullet-active {
                            background-position: -600px -150px
                        }
                    }

                    &:nth-child(8) {
                        background-position: -700px 0;

                        &.swiper-pagination-bullet-active {
                            background-position: -700px -150px
                        }
                    }

                    &:nth-child(9) {
                        background-position: -800px 0;

                        &.swiper-pagination-bullet-active {
                            background-position: -800px -150px
                        }
                    }
                }
            }
        }
        .swiper-container-3d .swiper-slide-shadow-left {
            background-image: none !important;
        }

        .swiper-container-3d .swiper-slide-shadow-right {
            background-image: none !important;
        }
        .swiper-slide {
            opacity: 0 !important;

            &.swiper-slide-active {
                opacity: 1 !important;
            }
        }

        @include laptop {
            > img {
                position: relative;
                left: 50%;
                transform: translateX(-50%);
                width: auto;
            }
        }

        @include mobile {
            > img {
                position: relative;
                left: 50%;
                transform: translateX(-50%);
                width: 100%
            }

            .swiperWrap {
                padding-bottom: 40px;

                .swiper-pagination {
                    position: relative;
                    z-index: 12;
                    justify-content: center;
                    display: inline-block;
                    overflow-x: auto;
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    margin-top: 40px;

                    .swiper-pagination-bullet {
                        background: url('../../public/images/mo_thumb_char.jpg') 0 0 no-repeat;
                        background-size: 550px;
                        width: 52px;
                        height: 70px;
                        margin-bottom: 10px;
                        box-sizing: border-box;
                        margin: 0 2px 2px;

                        &:nth-child(1).swiper-pagination-bullet-active {
                            background-position: 0 -77px;
                        }
                        &:nth-child(2) {
                            background-position: -62px 0;

                            &.swiper-pagination-bullet-active {
                                background-position: -62px -77px;
                            }
                        }
                        &:nth-child(3) {
                            background-position: -124px 0;

                            &.swiper-pagination-bullet-active {
                                background-position: -124px -77px;
                            }
                        }
                        &:nth-child(4) {
                            background-position: -187px 0;

                            &.swiper-pagination-bullet-active {
                                background-position: -187px -77px;
                            }
                        }
                        &:nth-child(5) {
                            background-position: -249px 0;

                            &.swiper-pagination-bullet-active {
                                background-position: -249px -77px;
                            }
                        }
                        &:nth-child(6) {
                            background-position: -311px 0;

                            &.swiper-pagination-bullet-active {
                                background-position: -311px -77px;
                            }
                        }
                        &:nth-child(7) {
                            background-position: -374px 0;

                            &.swiper-pagination-bullet-active {
                                background-position: -374px -77px;
                            }
                        }
                        &:nth-child(8) {
                            background-position: -436px 0;

                            &.swiper-pagination-bullet-active {
                                background-position: -436px -77px;
                            }
                        }
                        &:nth-child(9) {
                            background-position: -498px 0;

                            &.swiper-pagination-bullet-active {
                                background-position: -498px -77px;
                            }
                        }
                    }
                }

                .swiper-button-next {
                    right: -5px;

                    &:after {
                        font-size: 24px;
                    }
                }
                .swiper-button-prev {
                    left: -5px;

                    &:after {
                        font-size: 24px;
                    }
                }
                img {
                    width: 100%;
                }
                .txt {
                    position: relative;
                    z-index: 1;

                    &:after {
                        display: block;
                        clear: both;
                        content: '';
                        position: absolute;
                        bottom: -56%;
                        left: -10px;
                        background: linear-gradient(to right, rgba(0,0,0,0.8), transparent);
                        width: 100%;
                        height: 110%;
                        z-index: -1;
                    }
                }
            }
        }
    }



</style>