import Vue from 'vue'
import Vuex from 'vuex'
import * as _ from 'lodash'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        isResponse: undefined,
        isRegEndTime: false,
        loading: false,
        lang: 'en',
        categoryBoardType: "",
        rootPath: process.env.VUE_APP_ROOT_PATH,
        otherLang: '',
        isEventTime: false
    },
    mutations: {
        getIsResponse(state, payload) {
            state.isResponse = payload
        },
        setCategoryBoardType(state, value) {
            state.categoryBoardType = value
        },
    },
    getters: {
        getCategory(state) {
            return state.categoryBoardType
        }
    },
    actions: {
        checkResponse({ state, commit }, payload) {
            let windowWidth = window.matchMedia('all and (max-width:640px)');

            if (windowWidth.matches) {
                console.log('모바일')
                commit('getIsResponse', 'mobile')
            } else {
                console.log('피시')
                commit('getIsResponse', 'pc')
            }
        },
        fetchCategory({ state, commit }, value) {
            commit('setCategoryBoardType', value)
        },
        
    },
})
