<template>

    <div class="story">

        <div class="storyBox">

            <img :src="`./images/${$store.state.lang}/${getIsResponse}/titie_story.png`" />

            <Swiper class="swiperStoryWrap" :options="swiperOption">
                <SwiperSlide v-for="data in storyData"
                             :key="data.id">
                    <img :src="`./images/${$store.state.lang}/${getIsResponse}/${data.text}`" />
                    <div class="img_wrap">
                        <img class="front" :src="`./images/${$store.state.lang}/${getIsResponse}/${data.oneImg}`" />
                        <img class="back1" :src="`./images/${$store.state.lang}/${getIsResponse}/${data.twoImg}`" />
                        <img class="back2" :src="`./images/${$store.state.lang}/${getIsResponse}/${data.thrImg}`" />
                    </div>

                </SwiperSlide>

                <div class="swiper-button-prev" slot="button-prev" ></div>
                <div class="swiper-button-next" slot="button-next" ></div>
            </Swiper>

        </div>
    </div>

</template>

<script>
    import 'swiper/css/swiper.css'
    import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

    export default {
        components: {
            Swiper,
            SwiperSlide
        },
        data() {
            return {
                storyData: [
                    {
                        id: 0,
                        text: 'story/story01.png',
                        oneImg: 'story/story011.png',
                        twoImg: 'story/story012.png',
                        thrImg: 'story/story013.png',
                    },
                    {
                        id: 1,
                        text: 'story/story02.png',
                        oneImg: 'story/story021.png',
                        twoImg: 'story/story022.png',
                        thrImg: 'story/story023.png',
                    },
                    {
                        id: 2,
                        text: 'story/story03.png',
                        oneImg: 'story/story031.png',
                        twoImg: 'story/story032.png',
                        thrImg: 'story/story033.png',
                    },
                    {
                        id: 3,
                        text: 'story/story04.png',
                        oneImg: 'story/story041.png',
                        twoImg: 'story/story042.png',
                        thrImg: 'story/story043.png',
                    },
                    {
                        id: 4,
                        text: 'story/story05.png',
                        oneImg: 'story/story051.png',
                        twoImg: 'story/story052.png',
                        thrImg: 'story/story053.png',
                    },
                    {
                        id: 5,
                        text: 'story/story06.png',
                        oneImg: 'story/story061.png',
                        twoImg: 'story/story062.png',
                        thrImg: 'story/story063.png',
                    },
                    {
                        id: 6,
                        text: 'story/story07.png',
                        oneImg: 'story/story071.png',
                        twoImg: 'story/story072.png',
                        thrImg: 'story/story073.png',
                    },
                    {
                        id: 7,
                        text: 'story/story08.png',
                        oneImg: 'story/story081.png',
                        twoImg: 'story/story082.png',
                        thrImg: 'story/story083.png',
                    },
                    {
                        id: 8,
                        text: 'story/story09.png',
                        oneImg: 'story/story091.png',
                        twoImg: 'story/story092.png',
                        thrImg: 'story/story093.png',
                    },
                    {
                        id: 9,
                        text: 'story/story10.png',
                        oneImg: 'story/story101.png',
                        twoImg: 'story/story102.png',
                        thrImg: 'story/story103.png',
                    },
                ],
                swiperOption: {
                    slidesPerView: 'auto',
                    loop: true,
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    }
                },
            }
        },
        computed: {
            getIsResponse() {
                return this.$store.state.isResponse
            },
            rootPath() {
                return this.$store.state.rootPath
            }
        },
    }

</script>

<style lang="scss">
    @import '../assets/scss/_variables.scss';

    .story {
        position: relative;
        background: url('../../public/images/bg_story.jpg') center/cover no-repeat;


        .storyBox {
            width: 1200px;
            margin: 0 auto;
        }

        .swiperStoryWrap {
            .img_wrap {
                position: absolute;
                top: 0;
                left: 0;

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    opacity: 0;

                    &.front {
                        z-index: 1;
                    }
                }
            }
        }

        @keyframes front {
            0% {
                opacity: 0;
                display: none;
                top: 20px;
            }

            100% {
                opacity: 1;
                display: block;
                top: 0;
            }
        }

        @keyframes back1 {
            0% {
                opacity: 0;
                display: none;
                top: -10px;
                left: -10px;
            }

            100% {
                opacity: 1;
                display: block;
                top: 0;
                left: 0;
            }
        }

        @keyframes back2 {
            0% {
                opacity: 0;
                display: none;
                top: -10px;
                left: 10px;
            }

            100% {
                opacity: 1;
                display: block;
                top: 0;
                left: 0;
            }
        }

        .swiper-slide-active {

            .front {
                animation: front 0.4s 0.8s ease-in-out forwards;
            }

            .back1 {
                animation: back1 0.4s 0.4s ease-in-out forwards;
            }

            .back2 {
                animation: back2 0.4s ease-in-out forwards;
            }
        }

        @include laptop {
            .storyBox {
                width: 100%;
            }
        }

        @include mobile {
            .storyBox {
                >img {
                    width: 100%;
                }
            } .swiperStoryWrap {
                img {
                    width: 100%;
                }

                .img_wrap {
                    position: static;

                    img {

                        &.front {
                        }
                    }
                }
            }
        }
    }

  
    

</style>