<template>
    <div>

        <EventMain v-if="$store.state.isEventTime"></EventMain>

        <div v-else
             class="mainvisual">
            <div class="imgBox">
                <img v-for="img in motionImages"
                     :key="img.name"
                     :src="`./images/${$store.state.lang}/${getIsResponse}/${img.src}`"
                     :class="img.class" />
            </div>

            <div class="playBtnBox">
                <a v-for="btn in playBtn"
                   :key="btn.name"
                   :href="btn.href" target="_blank">
                    <img :src="`./images/${$store.state.lang}/${getIsResponse}/${btn.src}`" />
                </a>
            </div>

        </div>

        <!-- 사전예약 이벤트 시, 사용 할 메인비쥬얼 -->

    </div>
</template>

<script>
    // 사전예약 이벤트 시, 변경 할 메인비쥬얼
    import EventMain from '@/components/EventMain.vue'

    export default {
        components: {
            EventMain
        },
        data() {
            return {
                motionImages: [
                    {
                        name: 0,
                        src: 'kv/logo.png',
                        class: 'center'
                    },
                    {
                        name: 1,
                        src: 'kv/front.png',
                        class: 'front'
                    },
                    {
                        name: 2,
                        src: 'kv/left1.png',
                        class: 'left1'
                    },
                    {
                        name: 3,
                        src: 'kv/right1.png',
                        class: 'right1'
                    },
                    {
                        name: 4,
                        src: 'kv/left2.png',
                        class: 'left2'
                    },
                    {
                        name: 5,
                        src: 'kv/right2.png',
                        class: 'right2'
                    },
                    {
                        name: 6,
                        src: 'kv/back_left1.png',
                        class: 'backLeft1'
                    },
                    {
                        name: 7,
                        src: 'kv/back_right1.png',
                        class: 'backRight1'
                    },
                    {
                        name: 8,
                        src: 'kv/back_left2.png',
                        class: 'backLeft2'
                    },
                    {
                        name: 9,
                        src: 'kv/back_right2.png',
                        class: 'backRight2'
                    },
                    {
                        name: 10,
                        src: 'kv/back.png',
                        class: 'back'
                    },
                    {
                        name: 11,
                        src: 'kv/boss.png',
                        class: 'boss'
                    },
                 
                ],
                playBtn: [
                    {
                        name: 'androiod',
                        src: 'btn_google.png',
                        href: 'https://go.onelink.me/app/33198a2e'
                    },
                    {
                        name: 'ios',
                        src: 'btn_apple.png',
                        href: 'https://go.onelink.me/app/ea0bcca1'
                    }
                ],
            }
        },
        created() {
            this.$store.dispatch('checkResponse')
        },
        computed: {
            getIsResponse() {
                return this.$store.state.isResponse
            },
            rootPath() {
                return this.$store.state.rootPath
            },
        },
    }

</script>

<style lang="scss" scoped>
    @import '../assets/scss/_variables.scss';

    .mainvisual {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 1080px;
        background: url('../../public/images/bg.jpg') center/cover no-repeat;

        .backImg {
            text-align: center;
            margin: 0 auto;
        }

        .playBtnBox {
            position: absolute;
            bottom: 50px;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            
        }
    }
    .imgBox {
        position: absolute;
        width: 1920px;
        left: 50%;
        transform: translateX(-50%);
        top: 0;
        height: 100%;
        img {
            position: absolute;
            opacity: 0;

            &.center {
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                z-index: 6;
                animation: center 0.6s 0.4s ease-in-out forwards;
            }

            &.front {
                z-index: 5;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                animation: front2 0.6s 0.6s ease-in-out forwards;
            }

            &.left1 {
                z-index: 4;
                bottom: 0;
                left: 0;
                animation: left1 0.6s 0.8s ease-in-out forwards;
            }

            &.right1 {
                z-index: 4;
                right: 0;
                bottom: 0;
                animation: right1 0.6s 0.8s ease-in-out forwards;
            }

            &.left2 {
                z-index: 3;
                bottom: 0;
                left: 0;
                animation: left1 0.6s 1s ease-in-out forwards;
            }

            &.right2 {
                z-index: 3;
                right: 0;
                bottom: 0;
                animation: right1 0.6s 1s ease-in-out forwards;
            }

            &.backLeft1 {
                z-index: 2;
                top: 271px;
                left: 280px;
                animation: backleft1 0.6s 1.2s ease-in-out forwards;
            }

            &.backRight1 {
                z-index: 2;
                top: 271px;
                right: 280px;
                animation: backright1 0.6s 1.2s ease-in-out forwards;
            }

            &.backLeft2 {
                z-index: 1;
                top: 0;
                left: 0;
                animation: center 0.6s 1.4s ease-in-out forwards;
            }

            &.backRight2 {
                z-index: 1;
                top: 0;
                right: 0;
                animation: center 0.6s 1.4s ease-in-out forwards;
            }

            &.back {
                top: 0;
                left: 50%;
                margin-left: -325px;
                animation: boss 0.6s 1.6s ease-in-out forwards;
            }

            &.boss {
                display: none;
            }
        }
    }

    @keyframes center {
        0% {
            top: -50px;
            opacity: 0;
        }
        100% {
            top: 0;
            opacity: 1;
        }
    }
    @keyframes front2 {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    @keyframes left1 {
        0% {
            opacity: 0;
            left: 50px
        }

        100% {
            opacity: 1;
            left: 0;
        }
    }
    @keyframes right1 {
        0% {
            opacity: 0;
            right: 50px
        }

        100% {
            opacity: 1;
            right: 0;
        }
    }
    @keyframes backleft1 {
        0% {
            top: 321px;
            left: 330px;
            opacity: 0;
        }
        100% {
            opacity: 1;
            top: 271px;
            left: 280px;
        }
    }
    @keyframes backright1 {
        0% {
            top: 321px;
            right: 330px;
            opacity: 0;
        }

        100% {
            opacity: 1;
            top: 271px;
            right: 280px;
        }
    }
    @keyframes boss {
        0% {
            opacity: 0;
            transform: scale(0)
        }
        100% {
            transform: scale(1); opacity: 1;
        }
    }

    @media all and (max-width: 640px) {
        @keyframes backleft1 {
            0% {
                top: 50px;
                left: 50px;
                opacity: 0;
            }

            100% {
                opacity: 1;
                top: 0;
                left: 0;
            }
        }

        @keyframes backright1 {
            0% {
                top: 50px;
                right: 50px;
                opacity: 0;
            }

            100% {
                opacity: 1;
                top: 0;
                right: 0;
            }
        }

        .mainvisual {
            width: 100%;
            height: auto;
            padding-top: 177%;

            .imgBox {
                width: 100%;
                img {
                    width: 100%;

                    &.backLeft2 {
                        display: none;
                    }

                    &.backRight2 {
                        display: none;
                    }

                    &.back {
                        top: 0;
                        left: 0;
                        margin-left: 0;
                        animation: boss 0.6s 1.4s ease-in-out forwards;
                    }

                    &.boss {
                        display: block;
                        top: 0;
                        animation: boss 0.6s 1.6s ease-in-out forwards;
                    }
                }
            }

            .playBtnBox {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;

                a {
                    display: block;
                    width: 50%;

                    img {
                        width: 100%;
                    }
                }
            }
        }
      
    }

</style>