import Vue from 'vue'
import App from './App.vue'
import store from './store/index'
import router from './router'
import gsap from 'gsap'
import axios from 'axios'
import VueSmoothScroll from 'vue2-smooth-scroll'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCaretLeft, faCaretRight, faRss, faBullhorn, faCircleExclamation, faStar, faThumbtack, faBookmark, faList, faAngleLeft} from '@fortawesome/free-solid-svg-icons'
import vuetify from './plugins/vuetify'

library.add(faCaretLeft, faCaretRight, faRss, faBullhorn, faCircleExclamation, faStar, faThumbtack, faBookmark, faList, faAngleLeft)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

Vue.use(VueSmoothScroll)


Vue.prototype.$gsap = gsap
Vue.prototype.$axios = axios

let apiUrl = axios.create({
    baseURL: process.env.VUE_APP_API_URL
})

Vue.prototype.$apiUrl = apiUrl

Vue.config.productionTip = false

new Vue({
    store,
    router,
    axios,
    vuetify,
    render: h => h(App)
}).$mount('#app')
